html,
body {
  padding: 0;
  margin: 0;
  background: black;
  font-family: 'VT323', monospace !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

::-webkit-scrollbar {
  display: none;
  appearance: none;
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

a {
  color: inherit;
  text-decoration: none !important;
}

* {
  box-sizing: border-box;
}

code {
  font-family: 'VT323', monospace !important;
}
